import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Paper, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ContainerTop, FilterStyle } from "./style";
import Button from "../../components/buttom";
import "./style.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DefaultTemplate from "../../templates/default";
import {
  deleteUser,
  getUsersTeam,
  saveUserTeam,
} from "../../services/modules/user";
import { StaticTable } from "../../components/static-table";
import { DeleteOutline } from "@mui/icons-material";
import { DefaultButton } from "../../components/default-button";
import { setLoadingExternally } from "../../providers/loading.provider";
import { toast } from "react-toastify";
const trashBinIcon = require("./../../assets/images/icons/trash-bin.png");

const Usuarios = () => {
  const [userName, setUserName] = useState("");
  const [modalUserName, setModalUserName] = useState("");
  const [modalEmail, setModalEmail] = useState("");
  const [modalIsAdmin, setModalIsAdmin] = useState(false);
  const [deleteUserProps, setDeleteUserProps] = useState({ id: "", name: "" });
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false);
  const [openModalCreateUser, setOpenModalCreateUser] = useState(false);
  const [isLoadingGetUser, setIsLoadingGetUser] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory();
  const columns = [
    {
      id: "active",
      label: "",
      style: {
        width: "30px",
      },
    },
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "isAdmin",
      label: "Administrador",
    },
    {
      id: "delete",
      label: "",
      style: {
        width: "30px",
      },
    },
  ];
  const [debouncedTerm, setDebouncedTerm] = useState(userName);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(userName + "a");
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [userName]);

  useEffect(() => {
    if (debouncedTerm) {
      reloadSearch();
    }
  }, [debouncedTerm]);

  const formatUsers = (users: any[]): any => {
    return users.map((user: any) => {
      return {
        ...user,
        isAdmin: user.isAdmin ? (
          <span style={{ color: "blue" }}>Sim</span>
        ) : (
          <span style={{ color: "red" }}>Não</span>
        ),
        active: "",
        delete: (
          <DeleteOutline
            sx={{
              cursor: "pointer",
              width: "30px",
              color: "#FFA4A4",
              "&:hover": {
                color: "#FF7A7A",
              },
            }}
            onClick={() => {
              setDeleteUserProps({
                id: user.id,
                name: user.name,
              });
              setOpenModalDeleteUser(true);
            }}
          />
        ),
      };
    });
  };
  const findUsers = () => {
    setIsLoadingGetUser(true);
    getUsersTeam(userName)
      .then((data: any[]) => {
        setUsers(formatUsers(data));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetUser(false);
      });
  };

  const reloadSearch = () => {
    findUsers();
  };

  const createUser = () => {
    //api
    setLoadingExternally(true);
    saveUserTeam({
      name: modalUserName,
      email: modalEmail,
      isAdmin: modalIsAdmin,
    })
      .then(() => {
        setOpenModalCreateUser(false);
        reloadSearch();
        toast.success("Usuário cadastrado com sucesso!");
      })
      .catch(() => {
        setOpenModalCreateUser(false);
      })
      .finally(() => setLoadingExternally(false));
  };

  const deleteUserFromModal = () => {
    setLoadingExternally(true);
    deleteUser(deleteUserProps.id)
      .then(() => {
        setOpenModalDeleteUser(false);
        reloadSearch();
        toast.success("Usuário excluído com sucesso!");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => setLoadingExternally(false));
  };

  return (
    <DefaultTemplate title="Usuários" setContainer={false}>
      {/* Filter */}
      <ContainerTop>
        <FilterStyle>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "0",
              borderRadius: "10px",
              width: 400,
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquise por um usuário..."
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <IconButton
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={reloadSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </FilterStyle>
        <Button
          style={{ cursor: "pointer", width: "180px", height: "40px" }}
          text={"Novo Usuário"}
          callback={() => setOpenModalCreateUser(true)}
        />
      </ContainerTop>

      {/* Table */}
      <StaticTable
        rows={users}
        isLoading={isLoadingGetUser}
        columns={columns}
      />

      {/* Modal */}
      <Modal
        open={openModalCreateUser}
        onClose={() => setOpenModalCreateUser(false)}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Cadastro de Usuário
          </Typography>
          <TextField
            fullWidth
            type="text"
            label="Nome"
            value={modalUserName}
            onChange={(e) => setModalUserName(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={modalEmail}
            onChange={(e) => setModalEmail(e.target.value)}
            margin="normal"
          />
          <Divider orientation="horizontal" />
          <Typography variant="subtitle1" gutterBottom>
            Permissões
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={modalIsAdmin}
                onChange={(e) => setModalIsAdmin(e.target.checked)}
              />
            }
            label="Administrador"
          />
          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                style={{ backgroundColor: "#8f1515", width: "40%" }}
                onClick={() => setOpenModalCreateUser(false)}
              >
                Cancelar
              </DefaultButton>
              <DefaultButton style={{ width: "40%" }} onClick={createUser}>
                Salvar
              </DefaultButton>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModalDeleteUser}
        onClose={() => setOpenModalDeleteUser(false)}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40vw",
            height: "30vh",
            maxWidth: "900px",
            minWidth: "300px",
            minHeight: "260px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            src={trashBinIcon}
            sx={{
              width: "4vw",
              minWidth: "50px",
              height: "auto",
              borderRadius: 4,
            }}
          />

          <Typography variant="h6" component="h2" gutterBottom>
            Confirmar Exclusão
          </Typography>
          <Typography gutterBottom>
            Tem certeza que deseja excluir o usuário{" "}
            <b>{deleteUserProps.name}</b>?
          </Typography>
          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                onClick={() => setOpenModalDeleteUser(false)}
                style={{
                  color: "#585252",
                  backgroundColor: "#D9D9D9",
                  width: "40%",
                }}
              >
                Cancelar
              </DefaultButton>
              <DefaultButton
                onClick={() => deleteUserFromModal()}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#FFA4A4",
                  width: "40%",
                }}
              >
                Excluir
              </DefaultButton>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </DefaultTemplate>
  );
};

export default Usuarios;
