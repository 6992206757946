import { api } from "../../store/store";

export const getTeam = async () => {
  const response = await api.get("/teams");
  return response.data;
};
/* 
export const createClinicApi = async (clinic: any) => {
  const response = await api.post("/clinics", clinic);
  return response.data;
};


 */

export const updateTeamApi = async (team: any) => {
  const response = await api.put(`/teams`, team);
  return response.data;
};

export const updateLogoTeamApi = async (logo: any) => {
  const response = await api.put(`/teams/update-logo`, logo);
  return response.data;
};

export const updateLogoBackgroundTeamApi = async (logoBackground: any) => {
  const response = await api.put(
    `/teams/update-logo-background`,
    logoBackground
  );
  return response.data;
};

export const deleteTeamPicture = async (name: string) => {
  const response = await api.delete("/teams/picture", {
    data: {
      name,
    },
  });
  return response.data;
};
