import * as React from "react";
import GroupsIcon from "@mui/icons-material/Groups";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Switch } from "@mui/material";
import {
  BottomList,
  DrawerDiv,
  DrawerElement,
  ListItemTextElement,
  ResponsibleListItemButton,
  ResponsibleListItemIcon,
} from "./styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutAction } from "../../store/modules/user/reducer";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import "./styles.css";
import {
  CategoryOutlined,
  Diversity1Outlined,
  Store,
} from "@mui/icons-material";

interface SideBarProps {
  user: {
    name: string;
    email: string;
    isAdmin: boolean;
    isClient: boolean;
  };
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
}

export default function Sidebar({
  user,
  showSidebar,
  setShowSidebar,
}: SideBarProps) {
  const [darkMode, setDarkMode] = React.useState(false);
  /* const [isOnSmallScreen, setIsOnSmallScreen] = React.useState(false); */
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  /* const [windowWidth, setWindowWidht] = React.useState(window.innerWidth); */
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(logoutAction());
    history.push("/login");
  };

  React.useEffect(() => {
    const { pathname } = history.location;
    if (pathname === "/novo-laudo") {
      setSelectedIndex(0);
    } else if (pathname === "/laudos") {
      setSelectedIndex(1);
    } else if (pathname === "/perfil") {
      setSelectedIndex(2);
    } else if (pathname === "/usuarios") {
      setSelectedIndex(3);
    } else if (pathname === "/categorias") {
      setSelectedIndex(4);
    } else if (pathname === "/clinicas") {
      setSelectedIndex(5);
    } else if (pathname === "/equipe") {
      setSelectedIndex(6);
    }
  }, []);

  const openClinics = () => {
    history.push("/clinicas");
  };

  const openCategories = () => {
    history.push("/categorias");
  };

  const openUsers = () => {
    history.push("/usuarios");
  };

  const openNewReport = () => {
    history.push("/novo-laudo");
  };

  const openReports = () => {
    history.push("/laudos");
  };

  const openPerfil = () => {
    history.push("/perfil");
  };

  const openTeam = () => {
    history.push("/equipe");
  };

  return (
    <DrawerDiv
      showSidebar={showSidebar}
      className={showSidebar ? "sidebar-open" : ""}
    >
      <DrawerElement anchor="left" variant="permanent" open={showSidebar}>
        <List>
          <ListItem
            key="user"
            style={{
              margin: "15px 0 15px 0",
            }}
          >
            <ListItemTextElement
              open={showSidebar}
              disableTypography
              style={{
                fontWeight: "bold",
              }}
            >
              Olá, {user.name}{" "}
            </ListItemTextElement>
          </ListItem>

          <ResponsibleListItemButton
            open={showSidebar}
            key="Novo Laudo"
            selected={selectedIndex === 0}
            onClick={openNewReport}
          >
            <ResponsibleListItemIcon open={showSidebar}>
              <AssignmentOutlinedIcon />
            </ResponsibleListItemIcon>
            <ListItemTextElement open={showSidebar}>
              Novo Laudo
            </ListItemTextElement>
          </ResponsibleListItemButton>
          <ResponsibleListItemButton
            open={showSidebar}
            key="Pesquisar Laudo"
            selected={selectedIndex === 1}
            onClick={openReports}
          >
            <ResponsibleListItemIcon open={showSidebar}>
              <FindInPageOutlinedIcon />
            </ResponsibleListItemIcon>
            <ListItemTextElement open={showSidebar}>
              Pesquisar Laudos
            </ListItemTextElement>
          </ResponsibleListItemButton>
          {user.isAdmin ? (
            <ResponsibleListItemButton
              open={showSidebar}
              key="Usuários"
              selected={selectedIndex === 3}
              onClick={openUsers}
            >
              <ResponsibleListItemIcon open={showSidebar}>
                <GroupsIcon />
              </ResponsibleListItemIcon>
              <ListItemTextElement open={showSidebar}>
                Usuários
              </ListItemTextElement>
            </ResponsibleListItemButton>
          ) : null}
          {user.isAdmin ? (
            <ResponsibleListItemButton
              open={showSidebar}
              key="Equipe"
              selected={selectedIndex === 6}
              onClick={openTeam}
            >
              <ResponsibleListItemIcon open={showSidebar}>
                <Diversity1Outlined />
              </ResponsibleListItemIcon>
              <ListItemTextElement open={showSidebar}>
                Equipe
              </ListItemTextElement>
            </ResponsibleListItemButton>
          ) : null}
          {user.isAdmin || user.isClient ? (
            <ResponsibleListItemButton
              open={showSidebar}
              key="Categorias"
              selected={selectedIndex === 4}
              onClick={openCategories}
            >
              <ResponsibleListItemIcon open={showSidebar}>
                <CategoryOutlined />
              </ResponsibleListItemIcon>
              <ListItemTextElement open={showSidebar}>
                Categorias
              </ListItemTextElement>
            </ResponsibleListItemButton>
          ) : null}
          {user.isAdmin || user.isClient ? (
            <ResponsibleListItemButton
              open={showSidebar}
              key="Clinicas"
              selected={selectedIndex === 5}
              onClick={openClinics}
            >
              <ResponsibleListItemIcon open={showSidebar}>
                <Store />
              </ResponsibleListItemIcon>
              <ListItemTextElement open={showSidebar}>
                Clinicas
              </ListItemTextElement>
            </ResponsibleListItemButton>
          ) : null}
        </List>

        <BottomList>
          <ResponsibleListItemButton
            open={showSidebar}
            key="Fechar menu"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <ResponsibleListItemIcon open={showSidebar}>
              {showSidebar ? (
                <ArrowBackIosOutlinedIcon />
              ) : (
                <ArrowForwardIosOutlinedIcon />
              )}
            </ResponsibleListItemIcon>
            <ListItemTextElement open={showSidebar}>
              Fechar menu
            </ListItemTextElement>
          </ResponsibleListItemButton>
          <ResponsibleListItemButton
            open={showSidebar}
            key="Editar Perfil"
            selected={selectedIndex === 2}
            onClick={openPerfil}
          >
            <ResponsibleListItemIcon open={showSidebar}>
              <PersonOutlineOutlinedIcon />
            </ResponsibleListItemIcon>
            <ListItemTextElement open={showSidebar}>
              Editar Perfil
            </ListItemTextElement>
          </ResponsibleListItemButton>
          {/*          <ListItem key="Mode">
            <ResponsibleListItemIcon open={showSidebar}>
              <DarkModeOutlinedIcon />
            </ResponsibleListItemIcon>
            <ListItemTextElement
              open={showSidebar}
              style={{
                marginLeft: "15px",
              }}
            >
              Modo Escuro
            </ListItemTextElement>
            <Switch
              edge="end"
              onChange={() => setDarkMode(!darkMode)}
              checked={darkMode}
            />
          </ListItem> */}
          <ResponsibleListItemButton
            open={showSidebar}
            key="Logout"
            onClick={logout}
          >
            <ResponsibleListItemIcon open={showSidebar}>
              <MeetingRoomOutlinedIcon />
            </ResponsibleListItemIcon>
            <ListItemTextElement open={showSidebar}>Logout</ListItemTextElement>
          </ResponsibleListItemButton>
        </BottomList>
      </DrawerElement>
    </DrawerDiv>
  );
}
