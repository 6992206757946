import { styled } from "@mui/material";

export const Container = styled("div")({
  width: "100%",
  height: "100%",
  minWidth: "640px",
  marginBottom: "50px",
  backgroundColor: "#fff",
  position: "relative",
  padding: "2%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "10px",
  boxShadow: "0px 3px 3px 2px rgba(0, 0, 0, 0.125)",
  borderBottom: "2px solid #ECECEC",
});
