import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Button, TextField } from "@mui/material";
import EditOffIcon from "@mui/icons-material/EditOff";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DefaultTemplate from "../../templates/default";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { updateLocalUserData } from "../../store/modules/user/reducer";
import { useUserService } from "../../services/apiProvider";
import { setLoadingExternally } from "../../providers/loading.provider";
import { PictureSection } from "./picture-section";
import { deletePicture } from "../../services/modules/user";
import Password from "../../components/password-field";

const phoneRegex = new RegExp("^(\\(\\d{2}\\) \\d{4,5}-\\d{4})");
const cpfRegex = new RegExp(
  "(^(\\d{3}\\.\\d{3}.\\d{3}\\-\\d{2})?$)|(^(\\d{2}\\.\\d{3}\\.\\d{3}\\/\\d{4}-\\d{2}))"
);

// TODO: Ver como adicionar isso para validações de formulário
const validateSchema = yup.object().shape({
  email: yup.string().email("Email inválido").required("Informe um email"),
  password: yup.string().required("Informe uma senha"),
  document: yup
    .string()
    .matches(cpfRegex, "Documento incompativel")
    .required("O documento é necessário"),
  firstName: yup.string().required("O primeiro nome é necessário"),
  lastName: yup.string().required("O sobrenome é necessário"),
  phone: yup
    .string()
    .required("O telefone é necessário")
    .matches(phoneRegex, "Telefone inválido"),
});

const PerfilUsuario: React.FC = () => {
  const dispatch = useDispatch();

  const userService = useUserService();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState<any>({
    name: "",
    email: "",
    phone: "",
    crmv: "",
    password: "",
  });
  const [initialUserData, setInitialUserData] = useState<any>();
  /* const [selectedtImageFoto, setSelectedImageFoto] = useState<any | null>(null); */
  const [selectedtImageLogo, setSelectedImageLogo] = useState<any | null>(null);
  const [selectedtImageLogoBackground, setSelectedImageLogoBackground] =
    useState<any | null>(null);
  const [selectedtImageAssinatura, setSelectedImageAssinatura] = useState<
    any | null
  >(null);
  /*   const [previewImageFoto, setPreviewImageFoto] = useState<string | null>(
    userData?.picture
  ); */
  const [previewImageLogo, setPreviewImageLogo] = useState<string | null>(
    userData?.logo
  );
  const [previewImageLogoBackground, setPreviewImageLogoBackground] = useState<
    string | null
  >(userData?.logoBackground);
  const [previewImageAssinatura, setPreviewImageAssinatura] = useState<
    string | null
  >(userData?.signature);

  useEffect(() => {
    setLoadingExternally(true);
    userService
      .getUser()
      .then((data: any) => {
        setInitialUserData(data);
        setUserData(data);
        /* setPreviewImageFoto(data?.picture); */
        setPreviewImageLogo(data?.logo);
        setPreviewImageLogoBackground(data?.logoBackground);
        setPreviewImageAssinatura(data?.signature);
      })
      .finally(() => setLoadingExternally(false));
  }, []);

  const sendUserData = async () => {
    if (
      (userData.password || confirmPassword) &&
      userData.password !== confirmPassword
    ) {
      toast.error("As senhas não coincidem");
      return;
    }
    const userDataInfos = {
      name: userData.name,
      email: userData.email,
      crmv: userData.crmv,
      phone: userData.phone,
      type: userData?.type ?? "",
      password: userData.password,
    } as any;

    try {
      setLoadingExternally(true);
      const updateUserResponse = await userService.updateUser(userDataInfos);
      /*       if (selectedtImageFoto) {
        const formData = new FormData();
        formData.append("file", selectedtImageFoto);
        await userService.updateProfilePicture(formData);
      } */
      if (selectedtImageLogo) {
        const formData = new FormData();
        formData.append("file", selectedtImageLogo);
        await userService.updateLogoPicture(formData);
      }
      if (selectedtImageLogoBackground) {
        const formData = new FormData();
        formData.append("file", selectedtImageLogoBackground);
        await userService.updateLogoBackgroundPicture(formData);
      }
      if (selectedtImageAssinatura) {
        const formData = new FormData();
        formData.append("file", selectedtImageAssinatura);
        await userService.updateSignaturePicture(formData);
      }
      if (updateUserResponse) {
        dispatch(updateLocalUserData(updateUserResponse));
      }
      toast.success("Usuário atualizado com sucesso!");
    } catch (error) {
      toast.error(`Error updating user: ${error}`);
    }
    setLoadingExternally(false);
  };

  const deleteImage = (name: string) => {
    let sendName = {
      foto: "picture",
      logo: "logo",
      assinatura: "signature",
      logoBackground: "logoBackground",
    };
    setLoadingExternally(true);
    deletePicture(sendName[name])
      .then(() => {
        toast.success("Imagem deletada com sucesso!");
        /* if (name === "foto") {
          setPreviewImageFoto(null);
          setSelectedImageFoto(null);
        } else  */
        if (name === "logo") {
          setPreviewImageLogo(null);
          setSelectedImageLogo(null);
        } else if (name === "logoBackground") {
          setPreviewImageLogoBackground(null);
          setSelectedImageLogoBackground(null);
        } else if (name === "assinatura") {
          setPreviewImageAssinatura(null);
          setSelectedImageAssinatura(null);
        }
      })
      .finally(() => setLoadingExternally(false));
  };

  const clearUserData = () => {
    setUserData(initialUserData);
    /* setPreviewImageFoto(initialUserData?.picture);
    setSelectedImageFoto(null); */
    setPreviewImageLogo(initialUserData?.logo);
    setSelectedImageLogo(null);
    setPreviewImageLogoBackground(initialUserData?.logoBackground);
    setSelectedImageLogoBackground(null);
    setPreviewImageAssinatura(initialUserData?.signature);
    setSelectedImageAssinatura(null);
  };

  const updatePassword = (password: string) => {
    setUserData({
      ...userData,
      password,
    });
  };

  const formSection = (
    <div>
      <span
        style={{
          color: "#000000",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Informações
      </span>
      <div
        style={{
          display: "flex",
          gap: "2%",
          flexWrap: "wrap",
          paddingTop: "1.5%",
        }}
      >
        <div style={{ flexBasis: "48%" }}>
          <TextField
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            style={{ width: "100%" }}
            value={userData.name}
            onChange={(e) =>
              setUserData({
                ...userData,
                name: e.target.value,
              })
            }
          />
        </div>
        <div style={{ flexBasis: "48%" }}>
          <TextField
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            style={{ width: "100%" }}
            value={userData.email}
            onChange={(e) =>
              setUserData({
                ...userData,
                email: e.target.value,
              })
            }
          />
        </div>
        <div style={{ flexBasis: "28%", padding: "2.5% 0 2.5% 0" }}>
          <TextField
            id="outlined-basic"
            label="CRMV"
            variant="outlined"
            style={{ width: "100%" }}
            value={userData.crmv}
            onChange={(e) =>
              setUserData({
                ...userData,
                crmv: e.target.value,
              })
            }
          />
        </div>
        {/*         <div style={{ flexBasis: "28%", padding: "2.5% 0 2.5% 0" }}>
          <TextField
            id="outlined-basic"
            label="Especialidade"
            variant="outlined"
            style={{ width: "100%" }}
            value={userData.specialty}
            onChange={(e) =>
              setUserData({
                ...userData,
                speciality: e.target.value,
              })
            }
          />
        </div> */}
        <div style={{ flexBasis: "28%", padding: "2.5% 0 2.5% 0" }}>
          <InputMask
            mask="(99) 99999-9999"
            value={userData.phone}
            onChange={(e) =>
              setUserData({
                ...userData,
                phone: e.target.value,
              })
            }
          >
            {() => (
              <TextField
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                style={{ width: "100%" }}
              />
            )}
          </InputMask>
        </div>
        <div style={{ flexBasis: "100%" }}>
          <Password
            style={{
              width: "40%",
            }}
            label="Alterar senha"
            error={Boolean(
              confirmPassword && userData.password !== confirmPassword
            )}
            errorMessage="As senhas não coincidem"
            password={userData.password}
            setPassword={updatePassword}
          ></Password>

          <Password
            style={{
              width: "40%",
              marginLeft: "20px",
            }}
            label="Confirmar Senha"
            password={confirmPassword}
            setPassword={setConfirmPassword}
            error={Boolean(
              confirmPassword && userData.password !== confirmPassword
            )}
            errorMessage="As senhas não coincidem"
          ></Password>
        </div>
      </div>
    </div>
  );

  const buttonsSection = (
    <div style={{ display: "flex", justifyContent: "flex-start", gap: "2%" }}>
      <div>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<EditOffIcon />}
          style={{
            backgroundColor: "#D9D9D9",
            color: "#585252",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => clearUserData()}
        >
          Cancelar
        </Button>
      </div>
      <div>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<SaveAsIcon />}
          style={{
            backgroundColor: "#92EFBD",
            color: "#2A543D",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => sendUserData()}
        >
          Salvar
        </Button>
      </div>
    </div>
  );

  const content = (
    <>
      <div style={{ display: "flex", gap: "1%" }}>
        <div
          style={{
            flex: "0 0 70%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>{formSection}</div>
        </div>
        {/*         <div style={{ flex: "0 0 30%" }}>
          <div>
            <PictureSection
              setPreviewImage={setPreviewImageFoto}
              setSelectedImage={setSelectedImageFoto}
              image={userData?.image}
              previewImage={previewImageFoto}
              title="Foto de Perfil"
              name="foto"
              deleteFnct={deleteImage}
            />
          </div>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {userData.isClient && (
          <div>
            <PictureSection
              setPreviewImage={setPreviewImageLogo}
              setSelectedImage={setSelectedImageLogo}
              image={userData?.image}
              previewImage={previewImageLogo}
              title="Logo"
              name="logo"
              boxRight={true}
              isSquare={true}
              deleteFnct={deleteImage}
            />
          </div>
        )}
        <div>
          <PictureSection
            setPreviewImage={setPreviewImageAssinatura}
            setSelectedImage={setSelectedImageAssinatura}
            image={userData?.image}
            previewImage={previewImageAssinatura}
            title="Assinatura"
            name="assinatura"
            boxRight={true}
            isSquare={true}
            deleteFnct={deleteImage}
          />
        </div>
      </div>

      {userData.isClient && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div>
            <PictureSection
              setPreviewImage={setPreviewImageLogoBackground}
              setSelectedImage={setSelectedImageLogoBackground}
              image={userData?.image}
              previewImage={previewImageLogoBackground}
              title="Background da logo"
              name="logoBackground"
              boxRight={true}
              isSquare={true}
              deleteFnct={deleteImage}
            />
          </div>
        </div>
      )}

      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        {buttonsSection}
      </div>
    </>
  );

  return (
    <DefaultTemplate
      title="Perfil"
      mainPanelStyle={{
        minWidth: "960px",
      }}
    >
      {content}
    </DefaultTemplate>
  );
};

export default PerfilUsuario;
