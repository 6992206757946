import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { resetPassword } from "../../services/modules/user";
import queryString from "query-string";
import Password from "../../components/password-field";
import { useHistory } from "react-router-dom";
import AuthScreen from "../../components/auth-screen";
import { DefaultButton } from "../../components/default-button";
import { setLoadingExternally } from "../../providers/loading.provider";

const ResetarSenhaPage = (props: any) => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const history = useHistory();

  /* const [resetPassword, { isLoading }] = useResetPasswordMutation(); */

  const isSamePassword = () => password === confirmPassword;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!isSamePassword()) {
      setErrorPassword(true);
      toast.error("As senhas não são iguais");
      return;
    }
    setErrorPassword(false);
    setLoadingExternally(true);
    resetPassword({ email, token, password })
      .then((data) => {
        toast.success(data.message);
        history.push("/login");
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.data.message);
      })
      .finally(() => {
        setLoadingExternally(false);
      });
  };

  useEffect(() => {
    const queryParams = queryString.parse(props.location.search);
    if (!(queryParams.token && queryParams.email)) {
      toast.error(
        "Link inválido, confira novamente o link ou contate nosso suporte"
      );
      history.push("/login");
    }
    setToken(queryParams.token as string);
    setEmail(queryParams.email as string);
  }, []);

  useEffect(() => {
    if (errorPassword) {
      setErrorPassword(password !== confirmPassword);
    }
  }, [password, confirmPassword]);

  return (
    <AuthScreen onSubmit={handleSubmit}>
      <>
        <Password
          error={errorPassword}
          label="Insira sua nova senha"
          name="password"
          password={password}
          setPassword={setPassword}
          style={{ marginTop: "80px" }}
        />
        <Password
          error={errorPassword}
          label="Confirme sua nova senha"
          name="confirmPassword"
          password={confirmPassword}
          setPassword={setConfirmPassword}
        />
        <DefaultButton type="submit">Alterar senha</DefaultButton>
      </>
    </AuthScreen>
  );
};

export default ResetarSenhaPage;
