import React, { useState } from "react";
import { PasswordField } from "./style";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface PasswordProps {
  password: string;
  setPassword: (password: string) => void;
  maxLength?: number;
  label?: string;
  name?: string;
  error?: boolean;
  errorMessage?: string;
  style?: React.CSSProperties;
}
const Password: React.FC<PasswordProps> = ({
  password,
  setPassword,
  maxLength,
  label,
  name,
  error,
  errorMessage,
  style,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const updatePassword = (value: string) => {
    if (maxLength && value.length > maxLength) {
      return;
    }
    setPassword(value);
  };

  const updatePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <PasswordField
      error={error}
      helperText={error ? errorMessage : ""}
      variant="outlined"
      margin="normal"
      fullWidth
      name={name ?? "password"}
      label={label ?? "Senha"}
      type={showPassword ? "text" : "password"}
      id="outlined-adornment-password"
      style={style}
      value={password}
      onChange={(e) => updatePassword(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => updatePasswordVisibility()}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Password;
