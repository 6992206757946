import { useState } from "react";
import { toast } from "react-toastify";
import {
  EmailField,
  ForgotPasswordTitle,
  ForgotPasswordSubTitle,
} from "./style";
import { InputAdornment, Typography } from "@mui/material";
import { Email } from "@mui/icons-material";
import { recoverPassword } from "../../services/modules/user";
import AuthScreen from "../../components/auth-screen";
import { SubMessageLink } from "../../components/sub-message-link";
import { DefaultButton } from "../../components/default-button";
import { setLoadingExternally } from "../../providers/loading.provider";

const RecuperarSenha = () => {
  const [email, setEmail] = useState("");
  /* const [recoverPassword, { isLoading }] = useRecoverPasswordMutation(); */
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoadingExternally(true);
    recoverPassword(email)
      .then((data) => {
        toast.success(data.message);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoadingExternally(false);
      });
  };

  return (
    <AuthScreen onSubmit={handleSubmit}>
      <>
        <ForgotPasswordTitle variant="h6">
          Esqueceu sua senha?
        </ForgotPasswordTitle>
        <ForgotPasswordSubTitle>
          Digite seu e-mail que enviaremos um link para definir uma nova senha
        </ForgotPasswordSubTitle>
        <EmailField
          required
          id="outlined-multiline-flexible"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Email />
              </InputAdornment>
            ),
          }}
        />
        <SubMessageLink href="/login">Voltar para o Login</SubMessageLink>
        <DefaultButton type="submit">Enviar</DefaultButton>
      </>
    </AuthScreen>
  );
};

export default RecuperarSenha;
