import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DefaultTemplate from "../../templates/default";
import { toast } from "react-toastify";
import { setLoadingExternally } from "../../providers/loading.provider";
import { PictureSection } from "./picture-section";
import {
  deleteTeamPicture,
  getTeam,
  updateLogoBackgroundTeamApi,
  updateLogoTeamApi,
  updateTeamApi,
} from "../../services/modules/team";
import { deletePicture } from "../../services/modules/user";

interface Team {
  name: string;
  email: string;
  logo: string | null;
  logoBackground: string | null;
}

const Equipe: React.FC = () => {
  const [team, setTeam] = useState<Team>({
    name: "",
    email: "",
    logo: null,
    logoBackground: null,
  });
  const [selectedtImageLogo, setSelectedImageLogo] = useState<any | null>(null);
  const [selectedtImageLogoBackground, setSelectedImageLogoBackground] =
    useState<any | null>(null);
  const [previewImageLogo, setPreviewImageLogo] = useState<string | null>(
    team?.logo
  );
  const [previewImageLogoBackground, setPreviewImageLogoBackground] = useState<
    string | null
  >(team?.logoBackground);

  useEffect(() => {
    setLoadingExternally(true);
    getTeam()
      .then((data: any) => {
        setTeam(data);
        setPreviewImageLogo(data?.logo);
        setPreviewImageLogoBackground(data?.logoBackground);
      })
      .finally(() => setLoadingExternally(false));
  }, []);

  const sendTeam = async () => {
    const teamInfo = {
      name: team.name,
      email: team.email,
    } as any;

    try {
      setLoadingExternally(true);
      await updateTeamApi(teamInfo);
      if (selectedtImageLogo) {
        const formData = new FormData();
        formData.append("file", selectedtImageLogo);
        await updateLogoTeamApi(formData);
      }
      if (selectedtImageLogoBackground) {
        const formData = new FormData();
        formData.append("file", selectedtImageLogoBackground);
        await updateLogoBackgroundTeamApi(formData);
      }
      toast.success("Equipe atualizada com sucesso!");
    } catch (error) {
      toast.error(`Error updating user: ${error}`);
    }
    setLoadingExternally(false);
  };
  /* 
  const deleteImage = (name: string) => {
    let sendName = {
      foto: "picture",
      logo: "logo",
      assinatura: "signature",
    };
    setLoadingExternally(true);
    deletePicture(sendName[name])
      .then(() => {
        toast.success("Imagem deletada com sucesso!");
        if (name === "foto") {
          setPreviewImageLogo(null);
          setSelectedImageLogo(null);
        } else if (name === "logo") {
          setPreviewImageLogo(null);
          setSelectedImageLogo(null);
        } else if (name === "assinatura") {
          setPreviewImageAssinatura(null);
          setSelectedImageAssinatura(null);
        }
      })
      .finally(() => setLoadingExternally(false));
  };

  const clearUserData = () => {
    setUserData(initialUserData);
    setPreviewImageLogo(initialUserData?.picture);
    setSelectedImageLogo(null);
    setPreviewImageLogo(initialUserData?.logo);
    setSelectedImageLogo(null);
    setPreviewImageAssinatura(initialUserData?.signature);
    setSelectedImageAssinatura(null);
  }; */

  const formSection = (
    <div>
      <span
        style={{
          color: "#000000",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        Informações
      </span>
      <div
        style={{
          display: "flex",
          gap: "2%",
          flexWrap: "wrap",
          paddingTop: "1.5%",
        }}
      >
        <div style={{ flexBasis: "48%" }}>
          <TextField
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            style={{ width: "100%" }}
            value={team.name}
            onChange={(e) =>
              setTeam({
                ...team,
                name: e.target.value,
              })
            }
          />
        </div>
        <div style={{ flexBasis: "48%" }}>
          <TextField
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            style={{ width: "100%" }}
            value={team.email}
            onChange={(e) =>
              setTeam({
                ...team,
                email: e.target.value,
              })
            }
          />
        </div>
        {/*         <div style={{ flexBasis: "28%", padding: "2.5% 0 2.5% 0" }}>
          <TextField
            id="outlined-basic"
            label="Especialidade"
            variant="outlined"
            style={{ width: "100%" }}
            value={userData.specialty}
            onChange={(e) =>
              setUserData({
                ...userData,
                speciality: e.target.value,
              })
            }
          />
        </div> */}
        {/* <div style={{ flexBasis: "28%", padding: "2.5% 0 2.5% 0" }}>
          <InputMask
            mask="(99) 99999-9999"
            value={userData.phone}
            onChange={(e) =>
              setUserData({
                ...userData,
                phone: e.target.value,
              })
            }
          >
            {() => (
              <TextField
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                style={{ width: "100%" }}
              />
            )}
          </InputMask>
        </div> */}
      </div>
    </div>
  );

  const buttonsSection = (
    <div style={{ display: "flex", justifyContent: "flex-start", gap: "2%" }}>
      <div>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<SaveAsIcon />}
          style={{
            backgroundColor: "#92EFBD",
            color: "#2A543D",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => sendTeam()}
        >
          Salvar
        </Button>
      </div>
    </div>
  );

  const deleteImage = (name: string) => {
    let sendName = {
      foto: "picture",
      logo: "logo",
      assinatura: "signature",
      logoBackground: "logoBackground",
    };
    setLoadingExternally(true);
    deleteTeamPicture(sendName[name])
      .then(() => {
        toast.success("Imagem deletada com sucesso!");
        if (name === "logo") {
          setPreviewImageLogo(null);
          setSelectedImageLogo(null);
        } else if (name === "logoBackground") {
          setPreviewImageLogoBackground(null);
          setSelectedImageLogoBackground(null);
        }
      })
      .finally(() => setLoadingExternally(false));
  };

  const content = (
    <>
      <div style={{ display: "flex", gap: "1%" }}>
        <div
          style={{
            flex: "0 0 70%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>{formSection}</div>
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <PictureSection
            setPreviewImage={setPreviewImageLogo}
            setSelectedImage={setSelectedImageLogo}
            image={team?.logo}
            previewImage={previewImageLogo}
            title="Logo"
            name="logo"
            boxRight={true}
            isSquare={true}
            deleteFnct={deleteImage}
          />
        </div>
        <div>
          <PictureSection
            setPreviewImage={setPreviewImageLogoBackground}
            setSelectedImage={setSelectedImageLogoBackground}
            image={team?.logoBackground}
            previewImage={previewImageLogoBackground}
            title="Background da logo"
            name="logoBackground"
            boxRight={true}
            isSquare={true}
            deleteFnct={deleteImage}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        {buttonsSection}
      </div>
    </>
  );

  return (
    <DefaultTemplate
      title="Equipe"
      mainPanelStyle={{
        minWidth: "960px",
      }}
    >
      {content}
    </DefaultTemplate>
  );
};

export default Equipe;
