import React, { useState } from "react";
import {
  Button,
  Popover,
  Box,
  InputLabel,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";
import BasicDatePicker from "../../../components/date_ranger_picker";
import {
  BottomPopover,
  CleanFilterText,
  DivInputsFilter,
  HeaderPopover,
  TextFieldFilter,
} from "./style";

export interface FilterProps {
  suspectDiagnosis?: string;
  examDate?: Date;
  tutor?: string;
  pago?: string;
  clinic?: string;
}
interface PopoverFilterProps {
  filters: FilterProps;
  setFilters: React.Dispatch<React.SetStateAction<FilterProps>>;
  applyFilter: () => void;
}
const PopoverFilter: React.FC<PopoverFilterProps> = ({
  filters,
  setFilters,
  applyFilter,
}: PopoverFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openFilters = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    applyFilter();
    handleClose();
  };
  const handleReset = () => {
    setFilters({
      suspectDiagnosis: "",
      examDate: undefined,
      tutor: "",
      pago: "todos",
      clinic: "",
    });
    applyFilter();
    handleClose();
  };

  const clearField = (field: string) => {
    setFilters({
      ...filters,
      [field]: "",
      pago: "todos",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "filtro-popover" : undefined;

  return (
    <div>
      {/* Botão que abre o Popover */}
      <Button
        variant="contained"
        style={{
          color: "grey",
          fontWeight: "bold",
          fontSize: "14px",
          borderRadius: "10px",
          height: "40px",
          marginLeft: "10px",
          backgroundColor: "white",
          textTransform: "none",
        }}
        startIcon={<FilterAltOutlined />}
        onClick={openFilters}
      >
        Filtros
      </Button>

      {/* Popover que aparece abaixo do botão */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableEnforceFocus // Permite interação com o fundo
        PaperProps={{
          style: { width: "300px", borderRadius: "10px" }, // Tamanho e padding do Popover
        }}
      >
        <HeaderPopover>
          <Typography variant="h6">Filtros</Typography>
        </HeaderPopover>
        {/* Conteúdo do Popover */}
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            padding: "10px 10px 0 10px",
          }}
          gap={2}
        >
          <DivInputsFilter>
            <InputLabel htmlFor={"exameDate"}>
              <Typography>Data do exame</Typography>
            </InputLabel>
            <CleanFilterText onClick={() => clearField("examDate")}>
              Limpar
            </CleanFilterText>
            <BasicDatePicker
              fullWidth={true}
              dateValue={filters.examDate}
              setDateValue={(examDate) =>
                setFilters({
                  ...filters,
                  examDate,
                })
              }
              paddingTop="0px"
            />
          </DivInputsFilter>
          <DivInputsFilter>
            <InputLabel htmlFor={"tutor"}>
              <Typography>Tutor</Typography>
            </InputLabel>
            <CleanFilterText onClick={() => clearField("tutor")}>
              Limpar
            </CleanFilterText>
            <TextFieldFilter
              id="tutor"
              fullWidth
              value={filters.tutor}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  tutor: e.target.value,
                })
              }
            />
          </DivInputsFilter>
          <DivInputsFilter>
            <InputLabel htmlFor={"supect"}>
              <Typography>Suspeita</Typography>
            </InputLabel>
            <CleanFilterText onClick={() => clearField("suspectDiagnosis")}>
              Limpar
            </CleanFilterText>
            <TextFieldFilter
              id="supect"
              fullWidth
              value={filters.suspectDiagnosis}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  suspectDiagnosis: e.target.value,
                })
              }
            />
          </DivInputsFilter>
          <DivInputsFilter>
            <InputLabel htmlFor="pago">
              <Typography>Pago</Typography>
            </InputLabel>
            <CleanFilterText onClick={() => clearField("pago")}>
              Limpar
            </CleanFilterText>
            <FormControl fullWidth>
              <Select
                id="pago"
                fullWidth
                style={{ borderRadius: "10px" }}
                value={filters.pago}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    pago: e.target.value,
                  })
                }
              >
                <MenuItem value={"true"}>Sim</MenuItem>
                <MenuItem value={"false"}>Não</MenuItem>
                <MenuItem value={"todos"} selected>
                  Todos
                </MenuItem>
              </Select>
            </FormControl>
          </DivInputsFilter>
        </Box>
        <BottomPopover>
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#D9D9D9",
                fontWeight: "bold",
                color: "#585252",
              }}
              onClick={handleReset}
            >
              Resetar
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#92EFBD",
                fontWeight: "bold",
                color: "#2A543D",
              }}
              onClick={handleApply}
            >
              Aplicar
            </Button>
          </Box>
        </BottomPopover>
      </Popover>
    </div>
  );
};

export default PopoverFilter;
