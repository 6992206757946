import React from "react";
import { CircularProgress, styled } from "@mui/material";
import { LoadingCircularProps } from "../loading_circular";

const DivLoading = styled("div")({
  margin: "5px",
  display: "flex",
  justifyContent: "center",
  top: "40%",
  position: "fixed",
  left: "50%",
  transform: "translate(-50%, -50%)" /* Centraliza o elemento exato no ponto */,
  zIndex: 99999,
});

export const MiddleLoading = (props: LoadingCircularProps) => {
  return props.isLoading === true ? (
    <DivLoading>
      <CircularProgress />
    </DivLoading>
  ) : (
    <></>
  );
};
