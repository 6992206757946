import React, { useState } from "react";
import { ScreenStyles, Title, Content, MainPanel } from "./style";
import { useSelector } from "react-redux";

import ContentContainer from "../../components/content_container";
import Sidebar from "../../components/sidebar";

interface DefaultTemplateProps {
  title?: string;
  children?: React.ReactNode;
  setContainer?: boolean;
  mainPanelStyle?: any;
}

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
  title,
  children,
  setContainer,
  mainPanelStyle,
}) => {
  const [showSidebar, setShowSidebar] = React.useState(true);
  const user = useSelector((state: any) => state.user.user);

  return (
    <ScreenStyles>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        user={{
          email: user.email,
          name: user.name,
          isAdmin: user.isAdmin,
          isClient: user.isClient,
        }}
      />

      <MainPanel
        isShowingSidebar={showSidebar}
        style={mainPanelStyle ? mainPanelStyle : {}}
      >
        <Title isShowingSidebar={showSidebar}>{title ?? ""}</Title>
        <Content isShowingSidebar={showSidebar}>
          {setContainer === false ? (
            children
          ) : (
            <ContentContainer>{children}</ContentContainer>
          )}
        </Content>
      </MainPanel>
    </ScreenStyles>
  );
};

export default DefaultTemplate;
